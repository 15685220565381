<template>
	<div>
		<nav-component></nav-component>

		<order-status></order-status>
		<order-type></order-type>
		<payment-method></payment-method>
	</div>
</template>
<script>
export default {
	components: {
		NavComponent: () => import('@/components/abm/components/NavComponent'),
		OrderStatus: () => import('@/components/abm/components/OrderStatus'),
		OrderType: () => import('@/components/abm/components/OrderType'),
		PaymentMethod: () => import('@/components/abm/components/PaymentMethod'),
	},
}
</script>